<template>
  <b-sidebar
      id="sidebar"
      :visible="isSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      no-close-on-backdrop
      right
      @shown="loadData"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-sidebar-active', val)"
  >
      <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0 text-capitalize">
                  {{ titleForm }}
              </h5>
              <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
              />
          </div>

          <!-- BODY -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
              <!-- Form -->
              <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
              >
                  <!-- Name -->
                  <validation-provider
                      #default="validationContext"
                      name="name"
                      rules="required"
                  >
                      <b-form-group
                          :label="$t('safr.name')"
                          label-for="name"
                      >
                          <b-form-input
                              id="name"
                              v-model="itemData.name"
                              :state="getValidationState(validationContext)"
                              trim
                              :placeholder="$t('safr.placeholderName')"
                          />

                          <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>

                  <!-- Master Account -->
                  <validation-provider
                      #default="validationContext"
                      name="master_account"
                      rules="required"
                  >
                      <b-form-group
                          :label="$t('safr.master_account')"
                          label-for="master_account"
                          :state="getValidationState(validationContext)"
                      >
                          <v-select
                              label="user"
                              v-model="itemData.id_safr_master_account"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('safr.placeholderMasterAccount')"
                              :options="safrMasterAccounts"
                              :clearable="false"
                              :reduce="(account) => account.id"
                              input-id="master_account"
                          />

                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>

                  <!-- Level -->
                  <validation-provider
                      #default="validationContext"
                      name="level"
                      rules="required"
                  >
                      <b-form-group
                          :label="$t('safr.level')"
                          label-for="name"
                      >
                          <b-form-input
                              id="level"
                              type="number"
                              v-model="itemData.level"
                              :state="getValidationState(validationContext)"
                              trim
                              :placeholder="$t('safr.placeholderLevel')"
                          />

                          <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>

                  <!-- Enabled -->
                  <validation-provider
                      #default="validationContext"
                      name="enable"
                      rules="required"
                  >
                      <b-form-group
                          :label="$t('safr.enabled')"
                          label-for="enable"
                      >
                          <b-form-checkbox
                              id="enable"
                              v-model="itemData.enable"
                              name="enable"
                              :value="true"
                              :unchecked-value="false"
                              class="my-1 text-capitalize"
                              switch
                          />

                          <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-2 text-capitalize"
                          type="submit"
                      >
                          {{ isAdd ? $t('actions.add') :$t('actions.edit') }}
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="button"
                          variant="outline-secondary"
                          @click="hide"
                          class="text-capitalize"
                          >
                              {{$t('actions.cancel')}}
                      </b-button>
                  </div>
              </b-form>
          </validation-observer>
      </template>
  </b-sidebar>
</template>

<script>
import {
BSidebar,
BForm,
BFormGroup,
BFormInput,
BFormInvalidFeedback,
BButton,
BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref, onMounted } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from '@/libs/axios'

export default {
components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
},
directives: {
    Ripple,
},
model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
},
props: {
    isSidebarActive: {
        type: Boolean,
        required: true,
    },
    itemEdit: {
        type: Object,
        required: false,
    },
    isAdd: {
        type: Boolean,
        required: true,
    },
},
data() {
    return {
        required,
        alphaNum,
        email,
        positive,
    };
},
setup(props, { emit }) {
    const listSafrMasterAccounts = async () => {
        try {
            let requestList = { isList: true }

            let { data } = await axios.get('/v1/services/facial-recognition/safr/master-accounts', { params: requestList })

            safrMasterAccounts.value = data.data
        } catch (error) {
            let { message } = error.response.data

            contentToast(message,danger)
        }
    }

    onMounted(async () => await listSafrMasterAccounts());

    const blankData = {
        name: '',
        id_safr_master_account: null,
        level: 0,
        enable: true
    };

    //Composables
    const toast = useToast()

    const { t } = useI18nUtils()

    //Data
    const safrMasterAccounts = ref([])

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))

    const resetData = () => {
        itemData.value = JSON.parse(JSON.stringify(blankData))
    };

    //Computed
    const titleForm = computed(() => props.isAdd ? t('safr.addGroup') : ('safr.editGroup'))

    //Methods
    const contentToast = (message,type) => toast({
        component: ToastificationContent,
        props: {
            title: message,
            icon: 'CheckIcon',
            variant: type
        }
    })

    const onSubmit = async () => {
        try {
            let accessClearanceRequest = {
                accessClearances: [itemData.value]
            }

            let { data } = await axios.post('/v1/services/facial-recognition/safr/access-clearances', accessClearanceRequest)

            let { message } = data

            contentToast(message,'success')
            emit('refetch-data')
            emit('update:is-sidebar-active', false);
        } catch (error) {
            let { message } = error.response.data

            contentToast(message,'danger')
        }
    }

    const { refFormObserver, getValidationState, resetForm } =formValidation(resetData);

    const loadData = () => {
        if (!props.isAdd) {
            itemData.value = props.itemEdit;
        }
    };

    return {
        itemData,
        safrMasterAccounts,
        onSubmit,

        refFormObserver,
        getValidationState,
        loadData,
        resetForm,

        titleForm
    };
}
};
</script>
